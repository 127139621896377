import {
  Box,
  BoxProps,
  Center,
  chakra,
  Container,
  Flex,
  HStack,
  HTMLChakraProps,
  Link,
  List,
  ListItem,
} from "@chakra-ui/react"
import { BASE_URL, CORPORATE_BASE_URL } from "components/constants"
import NextImage from "next/image"
import NextLink, { LinkProps } from "next/link"
import { FooterBlankIcon } from "components/icons/icons"

/** 共通フッターコンポーネント
 */
export const Footer = (props: HTMLChakraProps<"footer">) => {
  return (
    <chakra.footer
      fontFamily={"Figtree, heisei-kaku-gothic-std, sans-serif"}
      bgColor="text-main-black"
      color={"white"}
      px={{ base: 4, lg: 16 }}
      pt={{ base: "64px", lg: 16 }}
      pb={8}
      borderTop={"1px solid #FFFFFF1A"}
      {...props}
    >
      <Container maxW={"1040px"} px={0}>
        <chakra.div mb={16} display={{ base: "none", lg: "block" }}>
          <NextLink href="/" passHref>
            <chakra.a display={"block"} lineHeight={0}>
              <NextImage
                src="/images/monicle-logo-white.svg"
                alt="monicle"
                layout="fixed"
                width="220px"
                height="55px"
              />
            </chakra.a>
          </NextLink>
        </chakra.div>
        <Box pb={8}>
          <HStack
            align="stretch"
            flexDirection={"column"}
            justify={{ lg: "stretch" }}
            gap={{ base: 8, lg: 16 }}
            w={"full"}
            spacing={0}
          >
            <Flex
              flexDirection={{ base: "column", lg: "row" }}
              flexWrap={"wrap"}
              gap={{ base: "48px", lg: 16 }}
              w={"full"}
            >
              {FooterLinks.map((item, id) => {
                return (
                  <chakra.dl
                    key={id}
                    flex={1}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"24px"}
                  >
                    <chakra.dt
                      fontFamily={"Figtree, sans-serif"}
                      fontWeight={600}
                      fontSize={"18px"}
                      lineHeight={1.2}
                      pb={"8px"}
                      borderBottom={"solid 1px #FFFFFF59"}
                      color="#FFFFFF59"
                    >
                      {item.label}
                    </chakra.dt>
                    <chakra.dd>
                      {item.links &&
                        item.links.length > 0 &&
                        (item.links.length > 5 ? (
                          <Flex gap={{ base: "16px", lg: "24px" }}>
                            <List
                              flex={1}
                              display={"flex"}
                              flexDirection={"column"}
                              gap={"24px"}
                              css={"column-count: 2"}
                            >
                              {item.links.slice(0, 5).map((link, i) => (
                                <ListItem
                                  key={i}
                                  fontSize={{ base: "14px", lg: "15px" }}
                                  fontWeight={700}
                                  lineHeight={1}
                                >
                                  <NextLink href={link.href} passHref>
                                    <Link
                                      display={"inline-flex"}
                                      alignItems={"center"}
                                      whiteSpace={"nowrap"}
                                      isExternal={link.external}
                                      position={"relative"}
                                      _hover={{
                                        textDecoration: "none",
                                        _before: {
                                          transform: "scaleX(1)",
                                          transformOrigin: "left center",
                                        },
                                      }}
                                      _before={{
                                        content: `""`,
                                        background: "#fff",
                                        height: "1px",
                                        inset: "100% 0 auto",
                                        position: "absolute",
                                        top: "calc(100% + 4px)",
                                        transform: "scaleX(0)",
                                        transformOrigin: "right center",
                                        transition: "transform 0.6s",
                                      }}
                                    >
                                      {link.label}
                                      {link.external && (
                                        <FooterBlankIcon
                                          w="15px"
                                          h="15px"
                                          fill="#fff"
                                          position={"absolute"}
                                          left={"calc(100% + 4px)"}
                                        />
                                      )}
                                    </Link>
                                  </NextLink>

                                  {link.childLinks &&
                                    link.childLinks.length > 0 && (
                                      <List
                                        display={"flex"}
                                        flexDirection={"column"}
                                        gap={"16px"}
                                        mt={{ base: 4, lg: "24px" }}
                                      >
                                        {link.childLinks.map(
                                          (childLink, id) => (
                                            <ListItem
                                              key={id}
                                              fontSize={{
                                                base: "13px",
                                                lg: "14px",
                                              }}
                                              fontWeight={700}
                                              lineHeight={1}
                                              color={"#FFFFFF8C"}
                                            >
                                              <NextLink
                                                href={childLink.href}
                                                passHref
                                              >
                                                <Link
                                                  display={"flex"}
                                                  alignItems={"center"}
                                                  gap={"4px"}
                                                  whiteSpace={"nowrap"}
                                                  isExternal={
                                                    childLink.external
                                                  }
                                                  _hover={{
                                                    textDecoration: "none",
                                                    color: "#fff",
                                                    svg: {
                                                      fill: "#fff",
                                                    },
                                                  }}
                                                >
                                                  {childLink.label}
                                                  {childLink.external && (
                                                    <FooterBlankIcon
                                                      w="14px"
                                                      h="14px"
                                                      fill={"#FFFFFF8C"}
                                                    />
                                                  )}
                                                </Link>
                                              </NextLink>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    )}
                                </ListItem>
                              ))}
                            </List>
                            <List
                              flex={1}
                              display={"flex"}
                              flexDirection={"column"}
                              gap={"24px"}
                              css={"column-count: 2"}
                            >
                              {item.links.slice(5).map((link, i) => (
                                <ListItem
                                  key={i}
                                  fontSize={{ base: "14px", lg: "15px" }}
                                  fontWeight={700}
                                  lineHeight={1}
                                >
                                  <NextLink href={link.href} passHref>
                                    <Link
                                      display={"inline-flex"}
                                      alignItems={"center"}
                                      whiteSpace={"nowrap"}
                                      position={"relative"}
                                      isExternal={link.external}
                                      _hover={{
                                        textDecoration: "none",
                                        _before: {
                                          transform: "scaleX(1)",
                                          transformOrigin: "left center",
                                        },
                                      }}
                                      _before={{
                                        content: `""`,
                                        background: "#fff",
                                        height: "1px",
                                        inset: "100% 0 auto",
                                        position: "absolute",
                                        top: "calc(100% + 4px)",
                                        transform: "scaleX(0)",
                                        transformOrigin: "right center",
                                        transition: "transform 0.6s",
                                      }}
                                    >
                                      {link.label}
                                      {link.external && (
                                        <FooterBlankIcon
                                          w="15px"
                                          h="15px"
                                          fill="#fff"
                                          position={"absolute"}
                                          left={"calc(100% + 4px)"}
                                        />
                                      )}
                                    </Link>
                                  </NextLink>

                                  {link.childLinks &&
                                    link.childLinks.length > 0 && (
                                      <List
                                        display={"flex"}
                                        flexDirection={"column"}
                                        gap={"16px"}
                                        mt={{ base: 4, lg: "24px" }}
                                      >
                                        {link.childLinks.map(
                                          (childLink, id) => (
                                            <ListItem
                                              key={id}
                                              fontSize={{
                                                base: "13px",
                                                lg: "14px",
                                              }}
                                              fontWeight={700}
                                              lineHeight={1}
                                              color={"#FFFFFF8C"}
                                            >
                                              <NextLink
                                                href={childLink.href}
                                                passHref
                                              >
                                                <Link
                                                  display={"inline-flex"}
                                                  alignItems={"center"}
                                                  gap={"4px"}
                                                  whiteSpace={"nowrap"}
                                                  isExternal={
                                                    childLink.external
                                                  }
                                                  _hover={{
                                                    textDecoration: "none",
                                                    color: "#fff",
                                                    svg: {
                                                      fill: "#fff",
                                                    },
                                                  }}
                                                >
                                                  {childLink.label}
                                                  {childLink.external && (
                                                    <FooterBlankIcon
                                                      w="14px"
                                                      h="14px"
                                                      fill={"#FFFFFF8C"}
                                                    />
                                                  )}
                                                </Link>
                                              </NextLink>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    )}
                                </ListItem>
                              ))}
                            </List>
                          </Flex>
                        ) : (
                          <List
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"24px"}
                            css={"column-count: 2"}
                          >
                            {item.links.map((link, i) => (
                              <ListItem
                                key={i}
                                fontSize={{ base: "14px", lg: "15px" }}
                                fontWeight={700}
                                lineHeight={1}
                              >
                                <NextLink href={link.href} passHref>
                                  <Link
                                    display={"inline-flex"}
                                    alignItems={"center"}
                                    whiteSpace={"nowrap"}
                                    isExternal={link.external}
                                    position={"relative"}
                                    _hover={{
                                      textDecoration: "none",
                                      _before: {
                                        transform: "scaleX(1)",
                                        transformOrigin: "left center",
                                      },
                                    }}
                                    _before={{
                                      content: `""`,
                                      background: "#fff",
                                      height: "1px",
                                      inset: "100% 0 auto",
                                      position: "absolute",
                                      top: "calc(100% + 4px)",
                                      transform: "scaleX(0)",
                                      transformOrigin: "right center",
                                      transition: "transform 0.6s",
                                    }}
                                  >
                                    {link.label}
                                    {link.external && (
                                      <FooterBlankIcon
                                        w="15px"
                                        h="15px"
                                        fill="#fff"
                                        position={"absolute"}
                                        left={"calc(100% + 4px)"}
                                      />
                                    )}
                                  </Link>
                                </NextLink>

                                {link.childLinks &&
                                  link.childLinks.length > 0 && (
                                    <List
                                      display={"flex"}
                                      flexDirection={"column"}
                                      gap={"16px"}
                                      mt={{ base: 4, lg: "24px" }}
                                    >
                                      {link.childLinks.map((childLink, id) => (
                                        <ListItem
                                          key={id}
                                          fontSize={{
                                            base: "13px",
                                            lg: "14px",
                                          }}
                                          fontWeight={700}
                                          lineHeight={1}
                                          color={"#FFFFFF8C"}
                                        >
                                          <NextLink
                                            href={childLink.href}
                                            passHref
                                          >
                                            <Link
                                              display={"inline-flex"}
                                              alignItems={"center"}
                                              gap={"4px"}
                                              whiteSpace={"nowrap"}
                                              isExternal={childLink.external}
                                              _hover={{
                                                textDecoration: "none",
                                                color: "#fff",
                                                svg: {
                                                  fill: "#fff",
                                                },
                                              }}
                                            >
                                              {childLink.label}
                                              {childLink.external && (
                                                <FooterBlankIcon
                                                  w="14px"
                                                  h="14px"
                                                  fill={"#FFFFFF8C"}
                                                />
                                              )}
                                            </Link>
                                          </NextLink>
                                        </ListItem>
                                      ))}
                                    </List>
                                  )}
                              </ListItem>
                            ))}
                          </List>
                        ))}
                    </chakra.dd>
                  </chakra.dl>
                )
              })}
            </Flex>
            <Flex
              flexDirection={{ base: "column", lg: "row" }}
              alignItems={{ base: "flex-start", lg: "center" }}
              justifyContent={"space-between"}
              gap={{ base: 8, lg: 0 }}
              ml={0}
            >
              <List
                w={{ base: "full", lg: "auto" }}
                display="flex"
                flexWrap={"wrap"}
                gap={{ base: "16px", lg: "24px" }}
              >
                {FooterSubLinks.map((link) => (
                  <ListItem key={link.key} lineHeight={1}>
                    <NextLink href={link.href} passHref>
                      <Link
                        whiteSpace={"nowrap"}
                        color={"#FFFFFF8C"}
                        fontSize={{ base: "12px", lg: "13px" }}
                        fontWeight={700}
                        lineHeight={1}
                        _hover={{
                          textDecoration: "none",
                          color: "#fff",
                        }}
                      >
                        {link.label}
                      </Link>
                    </NextLink>
                  </ListItem>
                ))}
              </List>
              <List display={"flex"} gap={"8px"}>
                <ListItem
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  lineHeight={0}
                >
                  <NextLink href={"https://x.com/monicle_Inc"} passHref>
                    <Link
                      opacity="0.55"
                      _hover={{
                        opacity: 1,
                      }}
                    >
                      <NextImage
                        src="/images/x-icon.svg"
                        alt="X"
                        layout="fixed"
                        width="40px"
                        height="40px"
                      />
                    </Link>
                  </NextLink>
                </ListItem>
              </List>
            </Flex>
          </HStack>
        </Box>

        <chakra.div
          display={{ base: "flex", lg: "block" }}
          alignItems={"center"}
          justifyContent={"space-between"}
          textAlign={"right"}
          pt={8}
          borderTop={"solid 1px #FFFFFF1A"}
        >
          <chakra.div display={{ base: "block", lg: "none" }}>
            <NextLink href="/" passHref>
              <chakra.a display={"block"} lineHeight={0}>
                <NextImage
                  src="/images/monicle-logo-white.svg"
                  alt="monicle"
                  layout="fixed"
                  width="129px"
                  height="32px"
                />
              </chakra.a>
            </NextLink>
          </chakra.div>
          <chakra.small color={"#FFFFFF59"}>&copy; Monicle Inc.</chakra.small>
        </chakra.div>
      </Container>
    </chakra.footer>
  )
}

const FooterLinks: {
  key: string
  label: string
  links: {
    href: LinkProps["href"]
    label: string
    external?: boolean
    childLinks?: {
      href: LinkProps["href"]
      label: string
      external?: boolean
    }[]
  }[]
}[] = [
  {
    key: "recruit",
    label: "RECRUIT",
    links: [
      {
        href: `${BASE_URL}`,
        label: "モニクル採用",
        childLinks: [
          { href: `${BASE_URL}#member`, label: "Member　働いている人びと" },
          {
            href: "https://developers.monicle.co.jp/",
            label: "Monicle developers",
            external: true,
          },
          // {href: "", label: "公式オウンドメディア", external: true},
        ],
      },
    ],
  },
  {
    key: "corporate",
    label: "CORPORATE",
    links: [
      { href: `${CORPORATE_BASE_URL}`, label: "トップ" },
      { href: `${CORPORATE_BASE_URL}/culture`, label: "カルチャー" },
      { href: `${CORPORATE_BASE_URL}/service`, label: "サービス" },
      { href: `${CORPORATE_BASE_URL}/news`, label: "ニュース" },
      { href: `${CORPORATE_BASE_URL}/contact`, label: "お問い合わせ" },
      {
        href: `${CORPORATE_BASE_URL}/company`,
        label: "企業情報",
        childLinks: [
          {
            href: `${CORPORATE_BASE_URL}/company/information`,
            label: "会社概要",
          },
          {
            href: `${CORPORATE_BASE_URL}/company/message`,
            label: "代表メッセージ",
          },
          {
            href: `${CORPORATE_BASE_URL}/company/leadership`,
            label: "役員紹介",
          },
          { href: `${CORPORATE_BASE_URL}/company/history`, label: "沿革" },
          {
            href: `${CORPORATE_BASE_URL}/company/group`,
            label: "グループ会社",
          },
        ],
      },
    ],
  },
  {
    key: "group",
    label: "GROUP COMPANY",
    links: [
      {
        href: "https://moniclefinancial.co.jp",
        label: "株式会社モニクルフィナンシャル",
        external: true,
      },
      {
        href: "https://monicleresearch.co.jp",
        label: "株式会社モニクルリサーチ",
        external: true,
      },
      {
        href: "https://plus.monicle.co.jp/",
        label: "グループ広報メディア",
        external: true,
      },
      {
        href: "https://monicle.co.jp/recruit",
        label: "グループ採用",
        external: true,
      },
    ],
  },
]

const FooterSubLinks: {
  key: string
  href: LinkProps["href"]
  label: string
}[] = [
  {
    key: "privacy",
    href: `${CORPORATE_BASE_URL}/privacy`,
    label: "個人情報保護方針",
  },
  {
    key: "security",
    href: `${CORPORATE_BASE_URL}/security`,
    label: "情報セキュリティ方針",
  },
  {
    key: "terms-of-use",
    href: `${CORPORATE_BASE_URL}/terms-of-use`,
    label: "ご利用にあたって",
  },
  {
    key: "external_transmission",
    href: `${CORPORATE_BASE_URL}/external_transmission`,
    label: "お客様情報の収集と外部送信について",
  },
  {
    key: "contact",
    href: `${CORPORATE_BASE_URL}/contact`,
    label: "お問い合わせ",
  },
]
